<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/department">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/department"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="setores-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="">
                        <div class="field field-checkbox">
                            <label for="ativo" class="mr-2 ml-0">Ativo</label>
                            <InputSwitch id="ativo" v-model="form.ativo" />
                        </div>
                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="customer_id">Cliente</label>
                            <DropdownClienteInscricao
                                id="customer_id"
                                v-model="form.customer"
                                :idValue="form.customerId"
                                :class="{ 'p-invalid': submitted && !form.customerId }"
                            />
                            <small class="p-error" v-if="submitted && !form.customerId">Cliente é obrigatório.</small>
                        </div>

                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="name">Setor</label>
                            <InputText
                                id="name"
                                v-model.trim="form.name"
                                required="true"
                                autofocus
                                autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.name }"
                            />
                            <small class="p-error" v-if="submitted && !form.name">Nome é obrigatório.</small>
                        </div>

                        <div class="field">
                            <span :style="{ color: 'red' }">*</span>
                            <label for="description">Descrição eSocial</label>
                            <Textarea
                                v-model.trim="form.description"
                                autocomplete="off"
                                :autoResize="true"
                                rows="3"
                                :class="{ 'p-invalid': submitted && !form.description }"
                            />
                            <small class="p-error" v-if="submitted && !form.description">Descrição é obrigatório.</small>
                        </div>

                        <div class="grid">
                            <div class="field col-6">
                                <label for="aso_observation">Observação (ASO)</label>
                                <InputText id="aso_observation" v-model.trim="form.asoObservation" autocomplete="off" />
                            </div>
                        </div>
                        <div class="grid">
                            <div class="field col-3">
                                <label for="codigoSoc">Código SOC</label>
                                <InputText id="codigoSoc" v-model.trim="form.codigoSoc" autocomplete="off" />
                            </div>

                            <div class="field col-3">
                                <label for="codigoExterno">Código externo</label>
                                <InputText id="codigoExterno" v-model.trim="form.codigoExterno" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany } from '@/services/store';
import DropdownClienteInscricao from '../clientes/components/DropdownClienteInscricao.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
        DropdownClienteInscricao,
        AppInfoManual
    },
    data() {
        return {
            form: {
                ativo: true
            },
            submitted: false
        };
    },
    created() {
        this.service = new BaseService('/customer/departments');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar setor' : 'Adicionar setor';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        }
    },
    watch: {
        'form.customer'() {
            this.form.customerId = this.form.customer?.id;
        },
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        }
    },
    methods: {
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave(response) {
            if (response.data.statusSincronizacaoSoc == 'ERRO') {
                this.$toast.add({ severity: 'error', summary: 'Erro na integração com SOC. Verifique o log de erros.', life: 3000 });
            }
            this.submitted = false;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.customerId) return true;
            if (!this.form.name) return true;
            if (!this.form.description) return true;
        }
    }
};
</script>

<style scoped lang="scss">
#customer_id {
    border-radius: 6px;
}
</style>
